import request from '@/service/request';

/**
 * 登录
 * @param data 登录参数
 * @param data.username 账号
 * @param data.password 密码
 */
export async function login(data) {
  return request('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  });
}

/**
 * 获取用户信息
 * @param data 登录参数
 * @param data.username 账号
 * @param data.password 密码
 */
export async function getProfile() {
  return request('/api/profile');
}

/**
 * 登出
 */
export async function logout() {
  return request('/api/logout', {
    method: 'DELETE',
    headers: {
      Authorization: `bearer ${localStorage.getItem('token')}`
    }
  });
}
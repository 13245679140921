const {
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL_SIT,
  REACT_APP_API_BASE_URL_PROD,
  REACT_APP_API_BASE_URL
} = process.env;
const serverConfig = {
  // 请求基础地址,可根据环境自定义
  API_BASE_URL: REACT_APP_ENV === 'sit' ? REACT_APP_API_BASE_URL_SIT : REACT_APP_ENV === 'prod' ? REACT_APP_API_BASE_URL_PROD : REACT_APP_API_BASE_URL,
  useTokenAuth: true // 是否开启 token 认证
};
export default serverConfig;